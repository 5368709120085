import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
// styles
import styles from "./Components/Pages/Media/Blogs/blogs.module.css";
import { useLocation } from "react-router-dom";

//blog json
import blogData from "./Components/Pages/Media/Blogs/blog.json";

import TestPagination from "./TestPagination";

export default function Blogs() {
  const location = useLocation();
  const [blogs, setBlogs] = useState([]);
  const [page, setPage] = useState(1);
  const [pagination, setpagination] = useState({
    blogsLength: 0,
    paginationBegining: 3,
    paginationCentral: 0,
    paginationEnd: 3,
  });

  const lowerCase = (value) => {
    return value.toLowerCase().replace("?", "").split(" ").join("-");
  };

  useEffect(() => {
    let reverseBlog = blogData.reverse();
    setBlogs(reverseBlog);
  }, [location, window.location]);

  if (blogs[0]?.id < 10) {
    let reverseBlog = blogData.reverse();
    setBlogs(reverseBlog);
  }

  useEffect(() => {
    let blogsLength = Math.ceil(blogs.length / 9);
    let paginationCentral =
      Math.ceil(blogsLength / 2) - pagination.paginationBegining;

    let paginationUpdatedValues = {
      blogsLength: blogsLength,
      paginationCentral: paginationCentral,
    };

    setpagination((prevValue) => {
      return { ...prevValue, ...paginationUpdatedValues };
    });
  }, [blogs]);

  function selectPage(selectedPage) {
    setPage(selectedPage);
    setpagination((prevValue) => {
      return { ...prevValue, paginationCentral: selectedPage };
    });
  }

  function paginationCentralNumbers(lowEnd, highEnd) {
    if (lowEnd <= pagination.paginationBegining) {
      return paginationCentralNumbers(
        pagination.paginationBegining + 1,
        pagination.paginationBegining + 6
      );
    }

    let list = [];
    for (var i = lowEnd; i <= highEnd; i++) {
      list.push(i);
    }
    return list;
  }

  // useEffect(() => {
  //   console.log(blogData.length);
  //   console.log(blogData.length / 9);
  // }, []);
  return (
    <>
      <main>
        {/* {blogs.length > 0 && <div>{[...Array(blogData.length / 10)]}</div>} */}

        <section className={styles.blogsMainContainer}>
          <section className={styles.blogsWidth}>
            <section className={styles.blogsItems}>
              {blogs.slice(page * 9 - 9, page * 9).map((item) => {
                return (
                  <article key={item.id} className={styles.blogItem}>
                    <img src={`${item.img}`} loading="lazy" />
                    <div className={styles.blogTitleLink}>
                      <h3>{item.title}</h3>
                      <h3>{item.id}</h3>
                      <Link to={`/blog/${lowerCase(item.link)}`}>
                        Know More
                      </Link>
                    </div>
                  </article>
                );
              })}
            </section>
            <section className={styles.blogsPagination}>
              <div>
                <button
                  onClick={() => setPage(page - 1)}
                  disabled={page <= 1 ? true : false}
                >
                  Prev
                </button>
              </div>
              <div className={styles.pagination}>
                <div className={styles.paginationbegining}>
                  {pagination.blogsLength > 1 &&
                    [...Array(pagination.blogsLength)].map((_, i) => {
                      if (i <= pagination.paginationBegining) {
                        return (
                          <span
                            onClick={() => selectPage(i + 1)}
                            key={i}
                            className={
                              page - 1 == i && styles.pagination_active
                            }
                          >
                            {i + 1}
                          </span>
                        );
                      }
                    })}
                </div>

                <div className={styles.paginationMiddle}>
                  {pagination.blogsLength > 1 &&
                    pagination.paginationCentral > 0 &&
                    paginationCentralNumbers(
                      pagination.paginationCentral - 3,
                      pagination.paginationCentral +
                        pagination.paginationBegining
                    ).map((item) => {
                      if (item <= pagination.blogsLength) {
                        return (
                          <span
                            onClick={() => selectPage(item)}
                            key={item}
                            className={page == item && styles.pagination_active}
                          >
                            {item}
                          </span>
                        );
                      }
                    })}
                </div>
                <div className={styles.paginationEnd}>
                  {pagination.blogsLength > 1 &&
                    pagination.paginationEnd > 1 &&
                    paginationCentralNumbers(
                      pagination.blogsLength - pagination.paginationEnd,
                      pagination.blogsLength
                    ).map((item) => {
                      return (
                        <span
                          onClick={() => selectPage(item)}
                          key={item}
                          className={page == item && styles.pagination_active}
                        >
                          {item}
                        </span>
                      );
                    })}
                </div>
              </div>
              <div>
                <button
                  onClick={() => setPage(page + 1)}
                  disabled={page == pagination.blogsLength ? true : false}
                >
                  Next
                </button>
              </div>
            </section>
          </section>
        </section>
      </main>
    </>
  );
}

// blogData.map((item) => {
//   console.log(
//     `<url> <loc>https://ovumhospitals.com/blog/${item.link}</loc> </url>`
//   );
// });
