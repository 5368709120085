import React from "react";
import styles from "./banner.module.css";
export default function Index(props) {
  const { title, titleColor, tag: TAG, img } = props;

  return (
    <>
      <section className={styles.BannerTitleContainer}>
        {img && <img src={img} />}

        <div className={styles.BannerBackground}></div>
        <TAG className={styles.BannerTag} style={{ color: titleColor }}>
          {title}
        </TAG>
      </section>
    </>
  );
}
