import React, { useEffect } from "react";
import { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { Helmet } from "react-helmet";
import Popup from "./Popup";
//styles
import "./branches.css";
import logo from "./assets/img/logo1.png";
export default function HsrPregnancyPackage() {
  async function callApi(name, email, phone) {
    if (name == "" || email == "" || phone == "") {
      alert("Please Fill The Details");
      return;
    }

    const regex = /^[6-9]\d{9}$/;

    if (!regex.test(phone)) {
      alert("Enter Correct Number");
      return;
    }

    const payload = {
      fname: name,
      email: email,
      phone: phone,
    };
    const options = {
      method: "POST", // Or 'PUT', 'DELETE', etc.
      headers: {
        "Content-Type": "application/json", // Specify the content type if sending JSON data
      },
      body: JSON.stringify(payload), // Convert the payload to JSON string
    };

    try {
      let response = await fetch(
        `https://teleduce.corefactors.in/lead/apiwebhook/25f9d6ae-afcf-4d6f-baf2-9ddddb1972a5/LandingPagePregnancyCareHSR/`,
        options
      );
      const result = await response.json();

      if (result.response_type == "error") {
        alert(result.response);
        return;
      }

      let form = document.forms["first_form"];
      form.submit();
    } catch (error) {
      alert("please check again after some time");
      console.error("Error fetching data:", error);
    }
  }
  // callApi();

  function handleSubmit(e) {
    e.preventDefault();

    let form = document.forms["first_form"];

    let name = form.elements["name-input"].value;
    let email = form.elements["email-input"].value;
    let phone = form.elements["mobno-input"].value;
    // let hospital = form.elements["hospital-name"].value;

    callApi(name, email, phone);
    // form.submit();
  }

  // const tagManagerArgs = {
  //   gtmId: "G-CW8MTK238W",
  // };

  // useEffect(() => {
  //   TagManager.initialize(tagManagerArgs);
  // }, []);

  return (
    <>
      {/* <!-- NavBar --> */}
      <Helmet>
        {/* latest tag */}
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=AW-11221938845"
        ></script>
        <script>
          {`window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments)}
  gtag('js', new Date());

  gtag('config', 'AW-11221938845');`}
        </script>
        {/*  */}

        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-CW8MTK238W"
        />
        <script>
          {`gtag('event', 'conversion', {'send_to': 'AW-11045475704/P7FaCK6wwpwYEPiq8pIp'})`}
        </script>
        <script>
          {`window.dataLayer = window.dataLayer || []; function gtag()
          {dataLayer.push(arguments)}
          gtag('js', new Date()); gtag('config', 'G-CW8MTK238W');`}
        </script>
        <script type="text/javascript">
          {`(function(c,l,a,r,i,t,y){
        c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
        t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
        y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
    })(window, document, "clarity", "script", "h27x9o7y7n");`}
        </script>
      </Helmet>
      <header className="header_main_container">
        <section className="header_width_container">
          <section className="header_items">
            <div className="header_logo">
              <a href="https://ovumhospitals.com/">
                <img src={logo} />
              </a>
            </div>
            <div className="header_buttons">
              <a href="tel:7795549696" className="footer_call">
                <i className="fa-solid fa-phone"></i> +91 7795549696
              </a>
              {/* <!-- <a href="https://wa.me/message/G4Q6REL6ECEHO1">Whatsapp</a> --> */}
            </div>
          </section>
        </section>
      </header>
      <Popup branchValue="hsrLayoutPregnancy" />

      {/* <section className="marquee_container">
        <p>
          <span>Discount of ₹ 4,999/- on Birthing Package (Banashankari) </span>
        </p>
      </section> */}
      {/* <!-- <div className="headerbottum"><h3>Your Partner in Parenthood</h3></div> --> */}
      <div className="mobile-banner"></div>
      <div className="container-fluid banner">
        <div className="banner_title banner_title_hsr_pregnancy">
          <h2>Best Maternity and Gynaecology Health Care in HSR Layout</h2>
          <p>
            Pregnancy Care Package <s>Rs. 25,000/-</s>{" "}
            <strong>Rs.&nbsp;9,999/&nbsp;-&nbsp;</strong> HSR Layout
          </p>
        </div>
        <div className="row">
          <div className="col-12 col-md-6 d-flex align-items-center">
            <div className="background-titles">
              {/* <!-- <div className="backgrond-title">
                <h2>
                  Making skin feel wonderful.
                </h2>
                <p>
                  It's just how we are.
                </p>
              </div> --> */}
            </div>
          </div>
          <div
            className="col-12 col-md-6 d-flex align-items-center"
            id="banner-form-link"
          >
            <div className="banner-form-container">
              <div className="banner-form">
                <h3>Book Your Appointment With Our Experts</h3>
                <form
                  method="POST"
                  action="https://digikit.in/forms/ovum-hospital/maternity/pregnancy.php"
                  id="first_form"
                  name="first_form"
                  onSubmit={handleSubmit}
                >
                  <div className="mb-3">
                    <input
                      required
                      type="text"
                      className="form-control"
                      id="name-input"
                      placeholder="Your Name"
                      name="name"
                    />
                  </div>

                  <div className="mb-3">
                    <input
                      required
                      type="email"
                      className="form-control"
                      id="email-input"
                      placeholder="Your Email"
                      name="email"
                    />
                  </div>
                  <div className="mb-3">
                    <input
                      required
                      type="tel"
                      pattern="^[6-9]\d{9}$"
                      className="form-control"
                      id="mobno-input"
                      placeholder="Phone Number"
                      name="phone"
                    />
                  </div>
                  <div className="mb-3">
                    <input
                      required
                      type="delivery"
                      className="form-control"
                      id="delivery-input"
                      placeholder="Expected Delivery Date"
                      name="delivery"
                    />
                  </div>

                  <div className="mb-3">
                    <input type="hidden" value="HSR" name="branch" />
                  </div>
                  {/* 

                  <div className="mb-3">
                    <select
                      id="hospital-name"
                      name="hospital-name"
                      className="select-branches"
                      required
                    >
                      <option value="" disabled selected>
                        Choose your nearest Branch
                      </option>
                      <option value="banashankari">Banashankari</option>
                    </select>
                  </div> */}

                  <div className="mb-3">
                    <input
                      required
                      type="submit"
                      value="Book a Consultation Now"
                      className="form-control"
                      id="exampleInputPassword"
                      placeholder="Enter Your Phone Number"
                    />
                  </div>

                  <div>
                    <input
                      type="hidden"
                      value={window.location.href}
                      name="urlValue"
                    />
                  </div>
                  <span className="form-bottom-content-span">
                    *Your privacy is our priority, your details are never shared
                    with any one.
                  </span>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Banner end --> */}
      <section className="offers-container">
        {" "}
        <h3>Our Specialities in Maternity</h3>
        <section className="offers-items">
          <article>
            <img src={require("./assets/img/icons/childbirth.jpg")} />
            <p>
              Normal Delivery, C-Section, <br /> & <br />
              Painless Delivery
            </p>
          </article>
          <article>
            <img src={require("./assets/img/icons/highrisk.jpg")} />
            <p>
              High-risk Pregnancy <br /> (Maternal Fetal Medicine)
            </p>
          </article>
          <article>
            <img src={require("./assets/img/icons/prepost.jpg")} />
            <p>
              Pre & Post-Natal Fitness <br />
              And <br /> Nutrition Sessions
            </p>
          </article>
          <article>
            <img src={require("./assets/img/icons/lactationsupport.jpg")} />
            <p>Lactation Support</p>
          </article>
          <article>
            <img src={require("./assets/img/icons/Neonatalcare.jpg")} />
            <p>Neonatal Care</p>
          </article>
        </section>
        <div className="buttondivtwo">
          <button className="buttonb">
            {" "}
            <a href="">Consult our Doctors</a>
          </button>
        </div>
      </section>
      {/* <section className="doctor-slide-container">
        <h3>Our Doctors</h3>
        <h5>Ovum Hospitals, Bengaluru</h5>
        <div className="slide-container swiper">
          <div className="slide-content">
            <Swiper
              slidesPerView={3}
              spaceBetween={25}
              loop={true}
              breakpoints={{
                0: {
                  slidesPerView: 1,
                },
                520: {
                  slidesPerView: 2,
                },
                950: {
                  slidesPerView: 3,
                },
              }}
              modules={[Navigation]}
              navigation={{
                nextEl: ".swiper-doc-btn-next",
                prevEl: ".swiper-doc-btn-prev",
              }}
              className="card-wrapper swiper-wrapper"
            >
              <SwiperSlide>
                <div className="card swiper-slide">
                  <div className="image-content">
                    <span className="overlay"></span>

                    <div className="card-image">
                      <img
                        src="https://cdn-doopl.nitrocdn.com/ThweVEdLHoiGldLbxJTyPcNIKzWqBzkt/assets/images/optimized/rev-ffb4984/wp-content/uploads/2022/01/dr-nandini-b-patil-min.jpg"
                        alt=""
                        className="card-img"
                      />
                    </div>
                  </div>

                  <div className="card-content">
                    <h2 className="name">Dr. Chandrika Anand</h2>
                    <p className="description">
                      MBBS, MD, DNB – Senior Obstetrics and Gynecologist
                    </p>

                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                {" "}
                <div className="card swiper-slide">
                  <div className="image-content">
                    <span className="overlay"></span>

                    <div className="card-image">
                      <img
                        src="https://ik.imagekit.io/d7t01fhx2/Ovum_Hospitals/Doctors/banashankari/Dr-Pavana.jpg?updatedAt=1680260560630"
                        alt=""
                        className="card-img"
                      />
                    </div>
                  </div>

                  <div className="card-content">
                    <h2 className="name">Dr. Pavana Prakash</h2>
                    <p className="description">TMBBS, DGO</p>


                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                {" "}
                <div className="card swiper-slide">
                  <div className="image-content">
                    <span className="overlay"></span>

                    <div className="card-image">
                      <img
                        src="https://ik.imagekit.io/d7t01fhx2/Ovum_Hospitals/Doctors/banashankari/aysha_.jpg?updatedAt=1682672698887"
                        alt=""
                        className="card-img"
                      />
                    </div>
                  </div>

                  <div className="card-content">
                    <h2 className="name">Dr. Ayesha Gulzar</h2>
                    <p className="description">MBBS, MS(OBG)</p>


                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                {" "}
                <div className="card swiper-slide">
                  <div className="image-content">
                    <span className="overlay"></span>

                    <div className="card-image">
                      <img
                        src="https://ik.imagekit.io/d7t01fhx2/Ovum_Hospitals/Doctors/banashankari/ramya_shankar.jpg?updatedAt=1682673402730"
                        alt=""
                        className="card-img"
                      />
                    </div>
                  </div>

                  <div className="card-content">
                    <h2 className="name">Dr. Ramya Shankar</h2>
                    <p className="description">
                      MBBS, DGO, DNB, Fellowship in Infertility ( IMA-AMS)
                    </p>


                  </div>
                </div>
              </SwiperSlide>

            </Swiper>
          </div>

          <div className="swiper-button-next swiper-navBtn swiper-doc-btn-next"></div>
          <div className="swiper-button-prev swiper-navBtn swiper-doc-btn-prev"></div>
          <div className="swiper-pagination"></div>
        </div>
      </section> */}
      {/* <div className="buttondiv">
        <button className="buttona">
          {" "}
          <a href="">Consult our Doctors</a>
        </button>
      </div> */}
      {/* <!-- doctor slider end --> */}
      {/* images */}
      <section className="why_choose_container">
        <section className="why_choose_items">
          <div>
            <img
              width="100%"
              className="verticlabaannerdoctor"
              src={require("./assets/img/modeldoctor.png")}
            />
          </div>
          <div>
            <h1>Why Choose Ovum Hospital</h1>
            <p>
              We offer the top amenities and services. Our doctors treat every
              patient with the utmost love and care, regardless of the different
              needs of the patient. Our mission statement is to raise awareness
              of new mothers and babies. Therefore, maintaining high standards
              while using advanced technology treatments.
            </p>
            <section className="why_choose_item">
              <article>
                <img src={require("./assets/img/icons2/branches.png")} />
                <p>7 branches in Bangalore</p>
              </article>
              <article>
                <img src={require("./assets/img/icons2/nurses.png")} />
                <p>Highly trained Gynaecologist and Nurses</p>
              </article>
              <article>
                <img src={require("./assets/img/icons2/ambulance.png")} />
                <p>Ambulances Equipped with Ventilators</p>
              </article>
              <article>
                <img src={require("./assets/img/icons2/helthydeliver.png")} />
                <p>15000+ Healthy Deliveries</p>
              </article>
              {/* <!-- <article>
                <img src={require("./assets/img/icons2/branches.png")} />
                <p>State-of-the-art infrastructure
                </p>
              </article> --> */}
              <article>
                <img src={require("./assets/img/icons2/24x7.png")} />
                <p>24x7 In-house Radiology, Diagnostics and Pharmacy</p>
              </article>
            </section>
          </div>
        </section>
        <div className="buttondivtwo">
          <button className="buttonb">
            {" "}
            <a href="">Consult our Doctors</a>
          </button>
        </div>
      </section>
      {/* images */}
      {/* //gallert  */}
      <section>
        <main className="gallery-heading">
          <h1>Gallery</h1>
          <h5>Ovum Hospitals, Bengaluru</h5>
        </main>
        <div className="swiper mySwiper">
          <Swiper
            className="swiper-wrapper"
            slidesPerView={1}
            spaceBetween={25}
            loop={true}
            modules={[Navigation]}
            navigation={{
              nextEl: ".swiper-gal-btn-next",
              prevEl: ".swiper-gal-btn-prev",
            }}
          >
            <SwiperSlide>
              {" "}
              <div className="swiper-slide">
                <img
                  width="103px"
                  src={require("./assets/img/icons/gallery/kotanur.jpg")}
                  alt=""
                />
              </div>{" "}
            </SwiperSlide>
            <SwiperSlide>
              {" "}
              <div className="swiper-slide">
                <img
                  width="10%"
                  src={require("./assets/img/icons/gallery/239A6561.44123386.jpg")}
                  alt=""
                />
              </div>{" "}
            </SwiperSlide>
            <SwiperSlide>
              <div className="swiper-slide">
                <img
                  width="103px"
                  src={require("./assets/img/icons/gallery/239A6827.a1f7c348.jpg")}
                  alt=""
                />
              </div>{" "}
            </SwiperSlide>
            <SwiperSlide>
              {" "}
              <div className="swiper-slide">
                <img
                  width="10%"
                  src={require("./assets/img/icons/gallery/banass.jpg")}
                  alt=""
                />
              </div>{" "}
            </SwiperSlide>
            <SwiperSlide>
              <div className="swiper-slide">
                <img
                  width="10%"
                  src={require("./assets/img/icons/gallery/meternity.jpg")}
                  alt=""
                />
              </div>{" "}
            </SwiperSlide>
            <SwiperSlide>
              <div className="swiper-slide">
                <img
                  width="10%"
                  src={require("./assets/img/icons/gallery/single_room_thumb.jpg")}
                  alt=""
                />
              </div>
            </SwiperSlide>
          </Swiper>

          <div className="swiper-button-next swiper-gal-btn-next"></div>
          <div className="swiper-button-prev swiper-gal-btn-prev"></div>
          <div className="swiper-pagination"></div>
        </div>
      </section>
      {/* <section className="testimonial_container">
        <h1>Testimonial</h1>
        <h5>Ovum Hospitals, Bengaluru</h5>
        <section className="testimonial_videos">
          <article>
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/Lm7XSDyBskk"
              srcDoc="<style>*{padding:0;margin:0;overflow:hidden}html,body{height:100%}img,span{position:absolute;width:100%;top:0;bottom:0;margin:auto}span{height:1.5em;text-align:center;font:48px/1.5 sans-serif;color:white;text-shadow:0 0 0.5em black}</style><a href=https://www.youtube.com/embed/Lm7XSDyBskk?autoplay=1><img src=https://img.youtube.com/vi/Lm7XSDyBskk/hqdefault.jpg alt='Video The Dark Knight Rises: What Went Wrong? – Wisecrack Edition'><span>▶</span></a>"
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              title="The Dark Knight Rises: What Went Wrong? – Wisecrack Edition"
            ></iframe>
          </article>
          <article>
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/gzZN34kXlqY"
              srcDoc="<style>*{padding:0;margin:0;overflow:hidden}html,body{height:100%}img,span{position:absolute;width:100%;top:0;bottom:0;margin:auto}span{height:1.5em;text-align:center;font:48px/1.5 sans-serif;color:white;text-shadow:0 0 0.5em black}</style><a href=https://www.youtube.com/embed/gzZN34kXlqY?autoplay=1><img src=https://img.youtube.com/vi/gzZN34kXlqY/hqdefault.jpg alt='Video The Dark Knight Rises: What Went Wrong? – Wisecrack Edition'><span>▶</span></a>"
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              title="The Dark Knight Rises: What Went Wrong? – Wisecrack Edition"
            ></iframe>
          </article>
          <article>
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/n18L9SdO46M"
              srcDoc="<style>*{padding:0;margin:0;overflow:hidden}html,body{height:100%}img,span{position:absolute;width:100%;top:0;bottom:0;margin:auto}span{height:1.5em;text-align:center;font:48px/1.5 sans-serif;color:white;text-shadow:0 0 0.5em black}</style><a href=https://www.youtube.com/embed/n18L9SdO46M?autoplay=1><img src=https://img.youtube.com/vi/n18L9SdO46M/hqdefault.jpg alt='Video The Dark Knight Rises: What Went Wrong? – Wisecrack Edition'><span>▶</span></a>"
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              title="The Dark Knight Rises: What Went Wrong? – Wisecrack Edition"
            ></iframe>
          </article>
          <article>
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/iEs3angFHU4"
              srcDoc="<style>*{padding:0;margin:0;overflow:hidden}html,body{height:100%}img,span{position:absolute;width:100%;top:0;bottom:0;margin:auto}span{height:1.5em;text-align:center;font:48px/1.5 sans-serif;color:white;text-shadow:0 0 0.5em black}</style><a href=https://www.youtube.com/embed/iEs3angFHU4?autoplay=1><img src=https://img.youtube.com/vi/iEs3angFHU4/hqdefault.jpg alt='Video The Dark Knight Rises: What Went Wrong? – Wisecrack Edition'><span>▶</span></a>"
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              title="The Dark Knight Rises: What Went Wrong? – Wisecrack Edition"
            ></iframe>
          </article>
          <div className="buttondivtwo">
            <button className="buttonb">
              {" "}
              <a href="">Consult our Doctors</a>
            </button>
          </div>
        </section>
      </section> */}

      {/* mobile */}

      <div className="mobile-bottom-container">
        <div className="mobile-bottom">
          <div className="mobile-bottom-1 bottomwhatsapp">
            <div className="mobile-bottom-img">
              <a href="#banner-form-link">
                <i className="fa-regular fa-calendar-check"></i>
              </a>
            </div>
            <div className="mobile-bottom-text">
              <a href="#banner-form-link"> Book Appointment</a>
            </div>
          </div>
          <div className="mobile-bottom-1 bottomcall">
            <div className="mobile-bottom-img">
              <i className="fa-solid fa-phone"></i>
            </div>
            <div className="mobile-bottom-text">
              <a href="tel:+917795549696"> Call Us</a>
            </div>
          </div>
        </div>
      </div>

      {/* mobile end */}
      <footer className="footer-main">
        <div className="footer-logo">
          <img src={require("./assets/img/logo1.png")} />
        </div>
        <div className="footer-brnches">
          <p>
            Banashankari | Kalyan Nagar | Hennur Road | Hoskote | Bhattarahalli
            | HSR Layout | Budigere Cross
          </p>
        </div>
        <p>Copyright © ovumhospitals.com, All rights reserved</p>
      </footer>
    </>
  );
}
