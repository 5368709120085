import React, { Suspense, lazy, useEffect } from "react";
import "./App.css";
import "./animations.css";

//browser router
import { Routes, Route, Link, Navigate, useLocation } from "react-router-dom";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// scroll to top
import ScrollToTop from "./Components/Assets/UiComponents/ScrollToTop";

//sidenav widget
import Sidenavwidget from "./Components/Assets/UiComponents/SideNavWidgets/Index";

//navbar
import Navbar from "./Components/HeaderFooter/Header/Index";

//redirection
import Redirection from "./Components/Assets/Redirection/Redirection";

//footer
import Footer from "./Components/HeaderFooter/Footer/Index";
import Ivf from "./Components/LandingPages/Ivf/Ivf";
import ThankYou from "./Components/LandingPages/Ivf/ThankYou";
import IvfDrChaithra from "./Components/LandingPages/Ivf/IvfDrChaithra";
import ChaitraThankYou from "./Components/LandingPages/Ivf/ChaitraThankYou";
import Error from "./Components/Pages/404/Error";
import DoctorSingleRedirection from "./Components/Pages/Doctors/DoctorSingle/DoctorSingleRedirection";
import DoctorProfileRedirection from "./Components/Pages/Doctors/DoctorSingle/DoctorProfileRedirection";
import SpecialityRedirection from "./Components/Pages/Specialities/SpecialitySingle/SpecialityRedirection";
import Title from "./Components/Seo/Title/Title";

//pages
import Test from "./Test";
import BlogImages from "./Components/Pages/Media/Blogs/BlogImages/BlogImages";
import AZContent from "./Components/Pages/AZContent/AZContent";
import AZContentSingle from "./Components/Pages/AZContent/Single/Single";
import HsrPregnancyPackage from "./Components/LandingPages/Branches/HsrPregnancyPackage";

//a to a
const AtoZ = lazy(() => import("./Components/Pages/A-Z/AtoZ"));
const AtoZSingle = lazy(() => import("./Components/Pages/A-Z/Single/Single"));
// import Popup
const Popup = lazy(() => import("./Components/Assets/PopUp/Popup"));

const Home = lazy(() => import("./Components/Pages/Home/Index"));
const Director = lazy(() => import("./Components/Pages/Director/Director"));
const About = lazy(() => import("./Components/Pages/About/About"));
const WhyOvum = lazy(() => import("./Components/Pages/About/WhyOvum"));

const BoardOfDirectors = lazy(() =>
  import("./Components/Pages/About/BoardOfDirectors/BoardOfDirectors")
);

const AboutTeam = lazy(() =>
  import("./Components/Pages/About/AboutTeam/AboutTeam")
);

const OurFacilities = lazy(() =>
  import("./Components/Pages/OurFacilities/OurFacilities")
);
const Contact = lazy(() => import("./Components/Pages/Contact/Contact"));
const Careers = lazy(() => import("./Components/Pages/Careers/Careers"));

//packages
const Packages = lazy(() => import("./Components/Pages/Packages/Packages"));

//doctor by branch
const Hoskote = lazy(() =>
  import("./Components/Pages/Doctors/DoctorsByBranch/Hoskote/Hoskote")
);
const Hennur = lazy(() =>
  import("./Components/Pages/Doctors/DoctorsByBranch/Hennur/Hennur")
);
const Bhattarahalli = lazy(() =>
  import(
    "./Components/Pages/Doctors/DoctorsByBranch/Bhattarahalli/Bhattarahalli"
  )
);
const KalyanNagar = lazy(() =>
  import("./Components/Pages/Doctors/DoctorsByBranch/KalyanNagar/KalyanNagar")
);
const BudigereCross = lazy(() =>
  import("./Components/Pages/Doctors/DoctorsByBranch/BudigereCross/BudigereCross")
);
const Banashankari = lazy(() =>
  import("./Components/Pages/Doctors/DoctorsByBranch/Banashankari/Banashankari")
);
const HsrLayout = lazy(() =>
  import("./Components/Pages/Doctors/DoctorsByBranch/HsrLayout/HsrLayout")
);
//doctor-single
const DoctorSingle = lazy(() =>
  import("./Components/Pages/Doctors/DoctorSingle/DoctorSingle")
);

//doctor-single
const SpecialitySingle = lazy(() =>
  import("./Components/Pages/Specialities/SpecialitySingle/Speciality")
);
//Testimonilas
const VideoTestimonials = lazy(() =>
  import("./Components/Pages/Testomonials/VideoTestimonials/VideoTestimonials")
);
//Media

//Gallery
const Gallery = lazy(() => import("./Components/Pages/Media/Gallery/Gallery"));

//Events
const Events = lazy(() => import("./Components/Pages/Media/Events/Events"));

const CSR = lazy(() => import("./Components/Pages/Media/CSR/Csr"));
const PressRelease = lazy(() =>
  import("./Components/Pages/Media/PressRelease/PressRelease")
);
const PatientTestimonials = lazy(() =>
  import("./Components/Pages/Media/PatientTestimonials/PatientTestimonials")
);
const DoctorTestimonials = lazy(() =>
  import("./Components/Pages/Media/DoctorTestimonials/DoctorTestimonials")
);
const FAQ = lazy(() => import("./Components/Pages/Media/Faq/Faq"));
//blogs
const Blogs = lazy(() => import("./Components/Pages/Media/Blogs/Blogs"));
const SingleBlog = lazy(() =>
  import("./Components/Pages/Media/Blogs/SingleBlogs/SingleBlog")
);
//centers
const Centers = lazy(() => import("./Components/Pages/Centers/Centers"));

//Landing Pages
const Maternity = lazy(() =>
  import("./Components/LandingPages/Maternity/Maternity")
);
const MaternityThankYou = lazy(() =>
  import("./Components/LandingPages/Maternity/ThankYou")
);

//  landing page branches
const Branches = lazy(() =>
  import("./Components/LandingPages/Branches/KalyanNagar")
);

const BranchesMaternity = lazy(() =>
  import("./Components/LandingPages/Branches/Hoskote")
);

const BranchesBsk = lazy(() =>
  import("./Components/LandingPages/Branches/Banashankari")
);

const BranchesBhat = lazy(() =>
  import("./Components/LandingPages/Branches/Bhattarahalli")
);

const BranchesHsr = lazy(() =>
  import("./Components/LandingPages/Branches/Hsr")
);

const BranchesHennur = lazy(() =>
  import("./Components/LandingPages/Branches/Hennur")
);

const ThankYouMain = lazy(() => import("./Components/Pages/ThankYou/ThankYou"));
const landingPageArray = [
  "/ovum-maternity",
  "ovum-maternity/",
  "/ovum-maternity/",
  "/ovum-maternity/thank-you",
  "/ovum-maternity/thank-you/",
  "/ovum-maternity/thank-you/",
  "/ovum-maternity/kalyan-nagar",
  "/ovum-maternity/Kalyan-Nagar/",
  "/ovum-maternity/hoskote",
  "/ovum-maternity/hoskote/",
  "/ovum-maternity/banashankari",
  "/ovum-maternity/banashankari/",
  "/ovum-maternity/bhattarahalli",
  "/ovum-maternity/bhattarahalli/",
  "/ovum-maternity/hsr-layout",
  "/ovum-maternity/hsr-layout/",
  "/ovum-maternity/hennur/",
  "/ovum-maternity/hennur",
  "/ovum-maternity/hsr-layout/pregnancy-care-package/",
  "/ovum-maternity/hsr-layout/pregnancy-care-package",

  //ivf
  "/ovum-ivf",
  "ovum-ivf/",
  "/ovum-ivf/",
  "/ovum-ivf/thank-you",
  "/ovum-ivf/thank-you/",
  "/ovum-ivf/thank-you/",

  //ivf-dr-chaithra
  "/ivf-dr-chaithra",
  "/ivf-dr-chaithra/thank-you",
];

let showHeader = true;

if (landingPageArray.includes(window.location.pathname)) {
  showHeader = false;
}

function App() {
  return (
    <>
      <Suspense fallback={<div>Loading...</div>}>
        <ScrollToTop />
        {showHeader && <Sidenavwidget />}
        {showHeader && <Navbar />}
        <Redirection />
        <Title />
        <Routes>
          <Route index element={<Home />} />
          {/* About Us */}
          <Route path="/a-z" element={<AtoZ />} />
          <Route path="/a-z-content" element={<AZContent />} />
          <Route
            path="/a-z-content/:aZletter/:azName"
            element={<AZContentSingle />}
          />
          {/* <Route path="/blog-images" element={<BlogImages />} /> */}
          <Route path="/test" element={<Test />} />
          <Route path="/a-zs" element={<AtoZSingle />} />
          <Route path="/director" element={<Director />} />
          <Route path="/about-us" element={<About />} />
          <Route path="/about-us/why-ovum" element={<WhyOvum />} />
          <Route path="/about-us/our-director" element={<BoardOfDirectors />} />
          <Route path="/about-us/our-team" element={<AboutTeam />} />
          <Route path="/our-facilities" element={<OurFacilities />} />
          {/* <Route path="/video-testimonials" element={<VideoTestimonials />} /> */}
          <Route
            path="/our-packages/:packageName/:packageId"
            element={<Packages />}
          />
          {/* 
          <Route path="/doctor-single" element={<DoctorSingle />} />*/}
          {/* <Route
            path="/our-doctors/:doctorName/:doctorId"
            element={<DoctorSingle />}
          /> */}
          {/* <Route path="/speciality-single" element={<SpecialitySingle />} /> */}
          <Route
            path="/our-specialities/:specialityName/:specialityId"
            element={<SpecialityRedirection />}
          />
          <Route
            path="/our-specialities/:specialityLink"
            element={<SpecialitySingle />}
          />
          <Route path="/our-doctors/:doctorName" element={<DoctorSingle />} />
          <Route
            path="/our-doctors/:doctorName/:doctorId"
            element={<DoctorSingleRedirection />}
          />
          <Route
            path="/profile/:profileLink"
            element={<DoctorProfileRedirection />}
          />
          <Route path="/doctors/hoskote" element={<Hoskote />} />
          <Route path="/doctors/hennur-road" element={<Hennur />} />
          <Route path="/doctors/bhattarahalli" element={<Bhattarahalli />} />
          <Route path="/doctors/kalyan-nagar" element={<KalyanNagar />} />
          <Route path="/doctors/banashankari" element={<Banashankari />} />
          <Route path="/doctors/hsr-layout" element={<HsrLayout />} />
          <Route path="/doctors/budigere-cross" element={<BudigereCross />} />
          {/* // centers  */}
          {/* 
          <Route
            path="/centers/:centerBranchName/:centerId"
            element={<Centers />}
            
          /> */}
          <Route path="/centers/:centerLink" element={<Centers />} />
          {/* // media  */}
          <Route path="/media/gallery" element={<Gallery />} />
          <Route path="/media/events" element={<Events />} />
          <Route path="/media/press-release" element={<PressRelease />} />
          <Route
            path="/media/patient-testimonials"
            element={<PatientTestimonials />}
          />{" "}
          <Route path="/media/doctors-talk" element={<DoctorTestimonials />} />
          <Route path="/media/faq" element={<FAQ />} />
          <Route
            path="/media/corporate-social-responsibility"
            element={<CSR />}
          />
          <Route path="/blog" element={<Blogs />} />
          <Route path="/blog/:blogsLink" element={<SingleBlog />} />
          <Route path="/careers" element={<Careers />} />
          <Route path="/contact" element={<Contact />} />
          {/* //landing pages  */}
          <Route path="/ovum-maternity" element={<Maternity />} />
          <Route
            path="/ovum-maternity/thank-you"
            element={<MaternityThankYou />}
          />
          <Route path="/ovum-maternity/kalyan-nagar" element={<Branches />} />
          <Route
            path="/ovum-maternity/hoskote"
            element={<BranchesMaternity />}
          />
          <Route
            path="/ovum-maternity/banashankari"
            element={<BranchesBsk />}
          />
          <Route
            path="/ovum-maternity/bhattarahalli"
            element={<BranchesBhat />}
          />
          <Route path="/ovum-maternity/hsr-layout" element={<BranchesHsr />} />
          <Route
            path="/ovum-maternity/hsr-layout/pregnancy-care-package"
            element={<HsrPregnancyPackage />}
          />
          <Route path="/ovum-maternity/hennur" element={<BranchesHennur />} />
          <Route path="/ovum-ivf" element={<Ivf />} />
          <Route path="/ovum-ivf/thank-you" element={<ThankYou />} />
          <Route path="/ivf-dr-chaithra" element={<IvfDrChaithra />} />
          <Route
            path="/ivf-dr-chaithra/thank-you"
            element={<ChaitraThankYou />}
          />
          <Route path="/thank-you" element={<ThankYouMain />} />
          <Route path="/404" element={<Error />} />
          <Route path="*" element={<Navigate to="/404" />} />
        </Routes>{" "}
        <Popup />
        {showHeader && <Footer />}
      </Suspense>
    </>
  );
}

export default App;

// {
//   id: "8",
//   title: "Success Story",
//   link: "/video-testimonials",
// },

// {
//   id: "5",
//   title: "Packages",
//   link: "/our-packages",
// },

// subMenu: [
//   { id: 2.1, title: "Our Directors", link: "/about-us/our-director" },
//   { id: 2.2, title: "Our Teams", link: "/about-us/our-team" },
// ],
