import React from "react";
import { useEffect } from "react";

import { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { Helmet } from "react-helmet";

import "./ivfPage.css";
export default function Ivf() {
  useEffect(() => {
    let fact_card_height = document.querySelector(".facts_cards").offsetHeight;
    let fact_card_content =
      document.querySelector(".facts_content").offsetHeight;

    document.querySelector(".facts_items").style.height = `${
      fact_card_content + fact_card_height - 50
    }px`;

    document.querySelector(".facts_cards").style.top = `-${
      fact_card_height / 2
    }px`;

    document.querySelector(".facts_content").style.bottom = `-${
      fact_card_content / 2
    }px`;
  }, []);

  async function callApi(name, email, phone, hospital) {
    var requestOptions = {
      mode: "no-cors",
    };
    const data = fetch(
      `https://ovummaternity.teknovatecrm.in/en_IN/lead?name=${name}&mobile=+91${phone}&email=${email}&note=${hospital}&city=${hospital}&source=8&company=1`,
      requestOptions
    );
    console.log("valled");
  }
  // callApi();

  function handleSubmit(e) {
    e.preventDefault();

    let form = document.forms["first_form"];

    let name = form.elements["name"].value;
    let email = form.elements["email"].value;
    let phone = form.elements["phone"].value;
    let hospital = "banashankari";
    // console.log(name, email, phone);
    // callApi(name, email, phone, hospital);
    form.submit();
  }
  return (
    <>
      <Helmet>

{/* latest tag */}
<script async src="https://www.googletagmanager.com/gtag/js?id=AW-11221938845"></script>
<script>
{`window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments)}
  gtag('js', new Date());

  gtag('config', 'AW-11221938845');`}
</script>
{/*  */}

        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-CW8MTK238W"
        />
        <script>
          {`gtag('event', 'conversion', {'send_to': 'AW-11045475704/P7FaCK6wwpwYEPiq8pIp'})`}
        </script>
        <script>
          {`window.dataLayer = window.dataLayer || []; function gtag()
          {dataLayer.push(arguments)}
          gtag('js', new Date()); gtag('config', 'G-CW8MTK238W');`}
        </script>
        <script type="text/javascript">
          {`  (function(c,l,a,r,i,t,y){
        c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
        t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
        y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
    })(window, document, "clarity", "script", "h26uvd0wx4");`}
        </script>
      </Helmet>
      <header class="header_main_container">
        <section class="header_main_width">
          <div class="header_logo">
            <img src="https://ovum-hospital.web.app/static/media/ovum-logo.6b08910b0e155f6fa04e.png" />
          </div>
          <div class="header_appiontment">
            <section class="header_appiontent_items">
              <div>
                <a href="tel:6364943214">
                  <i class="fa-solid fa-square-phone"></i> +91 6364943214
                </a>
              </div>
              <div>
                <a class="a-btn" href="">
                  <i class="fa-solid fa-calendar-check"></i>Book Appiontment
                </a>
              </div>
            </section>
          </div>
        </section>
      </header>
      {/* /// header end   */}
      <section class="banner_container">
        <div class="banner_width_items">
          <div class="banner_img"></div>
          <div class="banner_form">
            <form
              class="form_main_container"
              method="POST"
              action="https://digikit.in/forms/ovum-hospital/ivf/save2.php"
              id="first_form"
              name="first_form"
              onSubmit={handleSubmit}
            >
              <h3>Book Free Consultation</h3>
              <div>
                <i class="fa-solid fa-circle-user"></i>
                <input
                  type="text"
                  name="name"
                  id="name"
                  required
                  placeholder="Your Name"
                />
              </div>
              <div>
                <i class="fa-solid fa-envelope"></i>
                <input
                  type="email"
                  name="email"
                  id="email"
                  required
                  placeholder="Your Email"
                />
              </div>

              <div>
                <i class="fa-solid fa-square-phone"></i>
                <input
                  type="tel"
                  name="phone"
                  id="phone"
                  pattern="^[6-9]\d{9}$"
                  required
                  placeholder="Your Phone NUmber"
                />
              </div>

              <div>
                <input type="submit" value="Submit" />
              </div>
            </form>
          </div>
        </div>
      </section>
      {/* <!--Banner End--> */}
      <section class="why_container">
        <section class="why_width_container">
          <h3>What makes us the best choice?</h3>
          <section class="why_items">
            <div>
              <img
                src={require("./assets/img/icons/ExpertIVFpractitioners.png")}
              />
              <p>Expert IVF practitioners</p>
            </div>
            <div>
              <img
                src={require("./assets/img/icons/BudgetFriendlyoptions.png")}
              />
              <p>Budget Friendly options</p>
            </div>
            <div>
              <img src={require("./assets/img/icons/Highsuccessrate.png")} />
              <p>High success rate</p>
            </div>
            <div>
              <img src={require("./assets/img/icons/personalisedcare.png")} />
              <p>Personalised care</p>
            </div>
            <div>
              <img src={require("./assets/img/icons/Advancedtechnology.png")} />
              <p>Advanced technology</p>
            </div>
          </section>
        </section>
      </section>
      <section class="facts_main_container">
        <section class="facts_width">
          <section class="facts_items_position">
            <section class="facts_items">
              <section class="facts_cards"></section>

              <section class="facts_content">
                <h1>OVUM Hospital #1 IVF Center in Bangalore</h1>

                <p>
                  Every person has the right to experience the joys of
                  parenthood. We offer the highest care to support you
                  throughout your fertility journey. With our highly skilled
                  fertility specialists, trained embryologists, counsellors and
                  fertility-trained nurses, we work tirelessly to help you
                  achieve your dream of having a family.
                </p>
              </section>
            </section>
          </section>
        </section>
      </section>
      {/* <!--Why Ovum End--> */}
      <section class="doctorscontiner">
        <h1> OUR IVF SPECIALISTS </h1>
        <main class="doctorhorizontal">
          <article class="doctorsingle">
            <div class="doctorimage1">
              <img src="https://cdn-doopl.nitrocdn.com/ThweVEdLHoiGldLbxJTyPcNIKzWqBzkt/assets/images/optimized/rev-ffb4984/wp-content/uploads/2022/02/Dr-Prakrutha.jpg" />
            </div>
            <div>
              <span>Prakrutha S</span>
              <h3>MBBS, MS , FIRM</h3>
              <p>6+ Years Experience</p>
            </div>
          </article>
          <article class="doctorsingle">
            <div class="doctorimage1">
              <img src="https://cdn-doopl.nitrocdn.com/ThweVEdLHoiGldLbxJTyPcNIKzWqBzkt/assets/images/optimized/rev-ffb4984/wp-content/uploads/2022/02/Dr-Archana.jpg" />
            </div>
            <div>
              <span>Archana Karthik</span>
              <h3>MBBS, DGO, DNB, FICOG, FRM.</h3>
              <p>10+ Years Experience</p>
            </div>
          </article>
          {/* <article class="doctorsingle">
            <div class="doctorimage1">
              <img src="https://ik.imagekit.io/d7t01fhx2/Ovum_Hospitals/Doctors/kalyan-nagar/dr.Chaitra.jpg?updatedAt=1683549313807" />
            </div>
            <div>
              <span>
Dr. Chaithra S K</span>
              <h3>MBBS, MS-OBG, FRM, DRM</h3>
              <p>14+ Years Of Experience</p>
            </div>
          </article> */}
        </main>

        <div class="buttondiv" style={{ paddingBottom: "25px" }}>
          <button class="buttona">
            <a href="">Consult our Doctors</a>
          </button>
        </div>
      </section>
      {/* <!--our specialist end--> */}
      <section class="why_choose_container">
        <section class="why_choose_items">
          <div>
            <img
              width="100%"
              class="verticlabaannerdoctor"
              src={require("./assets/img/modeldoctor.png")}
            />
          </div>
          <div>
            <h1>Why Choose Ovum Hospital</h1>
            <p>
              Ovum hospital provides a diverse range of fertility treatments
              with a focus on quality care. As one of the top IVF treatment
              centers in Bangalore, we have advanced laboratories and a team of
              highly qualified and experienced fertility specialists who
              collaborate with patients to provide personalized treatment plans
              that enhance the likelihood of conception.
            </p>
            <br />
            <p>
              <strong>Reasons for taking IVF may include:</strong>
            </p>
            <ul>
              <li>Low sperm count</li>
              <li>Poor egg quality</li>
              <li>Uterus or Fallopian tube issues</li>
              <li>Ovulation Problems</li>
              <li>Unidentified infertility issues</li>
            </ul>
            <section class="why_appiontent_items">
              <div>
                <a href="+917795549696">
                  <i class="fa-solid fa-square-phone"></i> +91 7795549696
                </a>
              </div>
              <div>
                <a class="a-btn-new" href="">
                  <i class="fa-solid fa-calendar-check"></i>Book Appiontment
                </a>
              </div>
            </section>

            <section class="why_choose_item"></section>
          </div>
        </section>
      </section>
      <section>
        <main class="gallery-heading">
          <h1>Gallery</h1>
          <h5>Ovum Hospitals, Bengaluru</h5>
        </main>

        {/* <div class="swiper mySwiper">
          <div class="swiper-wrapper">
            <div class="swiper-slide">
              <img
                width="103px"
                src={require("./assets/img/icons/gallery/kotanur.jpg")}
                alt=""
              />
            </div>
            <div class="swiper-slide">
              <img
                width="10%"
                src={require("./assets/img/icons/gallery/239A6561.44123386.jpg")}
                alt=""
              />
            </div>
            <div class="swiper-slide">
              <img
                width="103px"
                src={require("./assets/img/icons/gallery/239A6827.a1f7c348.jpg")}
                alt=""
              />
            </div>
            <div class="swiper-slide">
              <img
                width="10%"
                src={require("./assets/img/icons/gallery/banass.jpg")}
                alt=""
              />
            </div>
            <div class="swiper-slide">
              <img
                width="10%"
                src={require("./assets/img/icons/gallery/meternity.jpg")}
                alt=""
              />
            </div>
            <div class="swiper-slide">
              <img
                width="10%"
                src={require("./assets/img/icons/gallery/single_room_thumb.jpg")}
                alt=""
              />
            </div>
          </div>
          <div class="swiper-button-next"></div>
          <div class="swiper-button-prev"></div>
          <div class="swiper-pagination"></div>
        </div> */}
        <Swiper
          slidesPerView={1}
          spaceBetween={25}
          loop={true}
          breakpoints={{
            0: {
              slidesPerView: 1,
            },
          }}
          modules={[Navigation]}
          className="swiper-wrapper"
        >
          <SwiperSlide>
            <div class="swiper-slide">
              <img
                width="103px"
                src={require("./assets/img/icons/gallery/kotanur.jpg")}
                alt=""
              />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div class="swiper-slide">
              <img
                width="10%"
                src={require("./assets/img/icons/gallery/239A6561.44123386.jpg")}
                alt=""
              />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div class="swiper-slide">
              <img
                width="103px"
                src={require("./assets/img/icons/gallery/239A6827.a1f7c348.jpg")}
                alt=""
              />
            </div>{" "}
          </SwiperSlide>
          <SwiperSlide>
            <div class="swiper-slide">
              <img
                width="10%"
                src={require("./assets/img/icons/gallery/banass.jpg")}
                alt=""
              />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div class="swiper-slide">
              <img
                width="10%"
                src={require("./assets/img/icons/gallery/meternity.jpg")}
                alt=""
              />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div class="swiper-slide">
              <img
                width="10%"
                src={require("./assets/img/icons/gallery/single_room_thumb.jpg")}
                alt=""
              />
            </div>
          </SwiperSlide>
        </Swiper>
      </section>
      <section class="testimonial_container">
        <div class="buttondiv">
          <button class="buttona">
            {" "}
            <a href="">Consult our Doctors</a>
          </button>
        </div>
      </section>
      <section class="feturecontent">
        <article>
          <h1> Service we offered</h1>
        </article>
        <main>
          <ul class="featurelist">
            <li>Expert Fertility Evaluation</li>
            <li>Fertility Correction Interventions (medical & Surgical)</li>
            <li>Andrology</li>
            <li>Male Erectile Dysfunction Treatment</li>
            <li>Intra Uterine Insemination (IUI)</li>
            <li>In Vitro Fertilisation (IVF)</li>
            <li>Intracytoplasmic sperm injection (ICSI)</li>
            <li>Cryopreservation/Freezing (Sperm/Egg/Embryo)</li>
            <li>Third-party Reproduction</li>
            <li>Professional Counselling</li>
          </ul>
        </main>
      </section>
      <div class="mobile-bottom-container">
        <div class="mobile-bottom">
          <div class="mobile-bottom-1 bottomwhatsapp">
            <div class="mobile-bottom-img">
              <i class="fa-regular fa-calendar-check"></i>
            </div>
            <div class="mobile-bottom-text">
              <a href="tel:+91 6364943214"> Book Appointment</a>
            </div>
          </div>
          <div class="mobile-bottom-1 bottomcall">
            <div class="mobile-bottom-img">
              <i class="fa-solid fa-phone"></i>
            </div>
            <div class="mobile-bottom-text">
              <a href="tel:+91 6364943214"> Call Us</a>
            </div>
          </div>
        </div>
      </div>
      <footer class="footer_main_container">
        <section class="footer_width_container">
          <section class="footer_items">
            <div class="footer_logo_container">
              <img src={require("./assets/img/logo1.png")} />
              <div class="footer_social_media"></div>
            </div>
            <div class="footer_contents">
              <a class="footer_link_a" href="">
                Contact Us{" "}
              </a>
              <p>
                <a href="tel:6364943214" class="footer_call">
                  <i class="fa-solid fa-phone"></i> +91 6364943214
                </a>
              </p>
              <address>
                #216 A, 100ft Outer Ring Road, Near Kathriguppe Signal, 2nd
                Block, 3rd Phase, Banashankari 3rd Stage, Bengaluru, Karnataka
                560085
              </address>
            </div>
          </section>
        </section>
      </footer>{" "}
      <p style={{ textAlign: "center" }}>
        Copyright © ovumhospitals.com, All rights reserved
      </p>
    </>
  );
}
