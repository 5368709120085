import React from "react";
//styles
import styles from "./accordian.module.css";
//icons
import { FaHandPointRight } from "react-icons/fa";

export default function Accordian({ item }) {
  return (
    <article
      className={styles.azContainerContentItem}
      id={`id${item.title.toLowerCase()}`}
    >
      <p>{item.title}</p>

      <ul>
        {item.contents.map((item) => {
          return (
            <li className={item.id}>
              <FaHandPointRight />
              <a
                href={`/a-z-content/${item.title.charAt(0).toLowerCase()}/${
                  item.link
                }`}
              >
                {" "}
                {item.title}
              </a>
            </li>
          );
        })}
      </ul>
    </article>
  );
}
