import React from "react";

//styles i
import styles from "./az.module.css";

// Banner title
import BannerTitle from "../../Assets/UiComponents/BannerTitle/Banner";
//data
import data from "../../Assets/Data/A-Z/content.json";
import Accordian from "./Accordian/Accordian";

export default function Csr() {
  return (
    <>
      <main>
        <section className={styles.azContainer}>
          <BannerTitle
            title="Health A to Z"
            titleColor="#fff"
            tag="h1"
            img="https://ik.imagekit.io/d7t01fhx2/Ovum_Hospitals/CSR/CSR.jpg?updatedAt=1679909976691"
          />
        </section>

        <section className={styles.azContent}>
          <section className={styles.azContentWidth}>
            <section className={styles.azContentLetters}>
              {data?.map((item) => {
                return (
                  <a href={`#id${item.title.toLowerCase()}`}>{item.title}</a>
                );
              })}
            </section>
            <section className={styles.azContainerContent}>
              {data?.map((item, index) => {
                return <Accordian item={item} key={index} />;
              })}
            </section>
          </section>
        </section>
      </main>
    </>
  );
}
