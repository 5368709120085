import React from "react";
import { useState } from "react";
//styles
import styles from "./pop.module.css";
import { AiFillCloseCircle } from "react-icons/ai";
import { useEffect } from "react";

const apiBranchLinks = {
  banashankari:
    "https://teleduce.corefactors.in/lead/apiwebhook/25f9d6ae-afcf-4d6f-baf2-9ddddb1972a5/POPUPBanashankari/",
  bhattarahalli:
    "https://teleduce.corefactors.in/lead/apiwebhook/25f9d6ae-afcf-4d6f-baf2-9ddddb1972a5/POPUPBhattarahalli/",
  hennur:
    "https://teleduce.corefactors.in/lead/apiwebhook/25f9d6ae-afcf-4d6f-baf2-9ddddb1972a5/POPUPHennur/",
  hoskote:
    "https://teleduce.corefactors.in/lead/apiwebhook/25f9d6ae-afcf-4d6f-baf2-9ddddb1972a5/POPUPHoskote/",
  hsrLayout:
    "https://teleduce.corefactors.in/lead/apiwebhook/25f9d6ae-afcf-4d6f-baf2-9ddddb1972a5/POPUPHSRLayout/",
  hsrLayoutPregnancy:
    "https://teleduce.corefactors.in/lead/apiwebhook/25f9d6ae-afcf-4d6f-baf2-9ddddb1972a5/POPUPPregnancyCareHSR/",
  kalyanNagar:
    "https://teleduce.corefactors.in/lead/apiwebhook/25f9d6ae-afcf-4d6f-baf2-9ddddb1972a5/POPUPKalyanNagar/",
  ovumMaternity:
    "https://teleduce.corefactors.in/lead/apiwebhook/25f9d6ae-afcf-4d6f-baf2-9ddddb1972a5/POPUPOvumMaternity/",
};

export default function Popup({ branchValue }) {
  const [show, setShow] = useState(false);

  const [form, setForm] = useState({
    fname: "",
    email: "",
    phone: "",
  });
  const handleChange = (e) => {
    let { name, value } = e.target;

    setForm((prevValue) => {
      return {
        ...prevValue,
        [name]: value,
      };
    });
  };

  useEffect(() => {
    setTimeout(() => {
      setShow(true);
    }, 1000);
  }, []);

  const handleActiveClick = () => {
    setShow(!show);
  };
  const apiLinkFilter = branchValue
    ? apiBranchLinks[branchValue]
    : "https://teleduce.corefactors.in/lead/apiwebhook/25f9d6ae-afcf-4d6f-baf2-9ddddb1972a5/WebsiteHomePage/";

  async function callApi(name, email, phone) {
    if (!name || !phone) {
      alert("Please Enter fill details");
      return;
    }

    const regex = /^[6-9]\d{9}$/;

    if (!regex.test(phone)) {
      alert("Enter Correct Number");
      return;
    }

    const payload = {
      fname: name,
      email: email,
      phone: phone,
    };
    const options = {
      method: "POST", // Or 'PUT', 'DELETE', etc.
      headers: {
        "Content-Type": "application/json", // Specify the content type if sending JSON data
      },
      body: JSON.stringify(payload), // Convert the payload to JSON string
    };

    try {
      let response = await fetch(apiLinkFilter, options);

      const result = await response.json();

      if (result.response_type == "error") {
        console.log(result);
        alert(result.response);
        return;
      }

      let form = document.forms["pop_upform"];
      form.submit();
    } catch (error) {
      alert("please check again after some time");
      console.error("Error fetching data:", error);
    }
  }

  function handleSubmit(e) {
    e.preventDefault();

    let form = document.forms["pop_upform"];

    let name = form.elements["fname"].value;
    let email = form.elements["email"].value;
    let phone = form.elements["phone"].value;

    callApi(name, email, phone);
    // form.submit();
  }
  return (
    <section
      className={`${styles.pop_up_container} ${
        show && styles.pop_up_container_active
      }`}
    >
      <div className={styles.pop_up_bg}></div>
      <div
        className={`${styles.pop_up_width}   ${
          show && styles.pop_up_form_show
        }`}
      >
        <div className={styles.pop_up_title}>
          <h3>Book a Consultation Now</h3>
          <AiFillCloseCircle onClick={() => handleActiveClick()} />
          <p>{form.fname.length > 1 && `Hi ${form.fname}.`}</p>
        </div>

        <form
          className={`${styles.pop_up_form}`}
          method="POST"
          action="https://digikit.in/forms/ovum-hospital/landing_popup/popup.php"
          id="pop_upform"
          name="pop_upform"
          onSubmit={handleSubmit}
        >
          <div>
            <input
              type="text"
              name="fname"
              placeholder="Name"
              value={form.fname}
              onChange={(e) => handleChange(e)}
              required
            />
          </div>
          <div>
            <input
              type="email"
              name="email"
              placeholder="Email"
              value={form.email}
              onChange={(e) => handleChange(e)}
              required
            />
          </div>
          <div>
            <input
              type="tel"
              pattern="^[6-9]\d{9}$"
              name="phone"
              placeholder="Phone Number"
              value={form.phone}
              onChange={(e) => handleChange(e)}
              required
            />
          </div>
          <input type="hidden" value={window.location.href} name="urlValue" />
          <input type="hidden" value={branchValue} name="branch" />
          <div>
            <input type="submit" value="Submit" />
          </div>
        </form>
      </div>
    </section>
  );
}
