import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import seoData from "../../Assets/Data/TitleTag/titletag.json";

export default function Title() {
  const { location } = useLocation();

  const { pathname } = window.location;

  useEffect(() => {
    let currentPathname = window.location.pathname;
    if (currentPathname.slice(-1) === "/") {
      currentPathname = currentPathname.substr(0, currentPathname.length - 1);
    }

    if (currentPathname == 0 || currentPathname == "/") {
      currentPathname = "/";
    }
    const filteredObject = seoData.find((obj) => obj.link === currentPathname);

    if (filteredObject) {
      document.title = filteredObject.title;
      const metaDescription = document.querySelector(
        'meta[name="description"]'
      );

      if (metaDescription) {
        metaDescription.setAttribute("content", filteredObject.description);
      }
    }

    const canonicalLink = document.querySelector('link[rel="canonical"]');

    if (canonicalLink) {
      canonicalLink.setAttribute("href", window.location.href);
    }
  }, [location, pathname]);
}
