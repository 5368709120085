import React, { useEffect } from "react";
import { redirect, useLocation } from "react-router-dom";
//browser router
import { useNavigate } from "react-router-dom";

export default function Redirection() {
  const { location } = useLocation();
  const { pathname } = window.location;

  const RedirectionArray = [
    {
      link: ["/doctors/hennur", "/hennur-doctor"],
      redirect: "/doctors/hennur-road",
    },
    {
      link: ["/bhattarahalli", "/centers/bhattarahalli/5"],
      redirect: "/centers/bhattarahalli",
    },
    {
      link: [
        "/hennur",
        "/hennur/hennur-2",
        "/centers/hennur/4",
        "/centers/hennur",
        "/location/hennur",
      ],
      redirect: "/centers/hennur-road",
    },
    {
      link: ["/kalyannagar", "/centers/kalyan-nagar/1"],
      redirect: "/centers/kalyan-nagar",
    },
    {
      link: ["/hoskote", "/centers/hoskote/2"],
      redirect: "/centers/hoskote",
    },
    {
      link: ["/banashankari", "/centers/banashankari/3"],
      redirect: "/centers/banashankari",
    },
    {
      link: ["/bhattarahalli-doctors"],
      redirect: "/doctors/bhattarahalli",
    },
    {
      link: ["/alldoctors"],
      redirect: "/doctors/banashankari",
    },

    {
      link: ["/hoskote-doctors"],
      redirect: "/doctors/hoskote",
    },
    {
      link: ["/kalyannagar-doctors"],
      redirect: "/doctors/kalyan-nagar",
    },
    {
      link: ["/lactation"],
      redirect: "/our-specialities/lactation",
    },

    {
      link: ["/location/all-locations"],
      redirect: "/centers/kalyan-nagar",
    },
    {
      link: ["/location/banashankari"],
      redirect: "/centers/banashankari",
    },
    {
      link: ["/location/hoskote"],
      redirect: "/centers/hoskote",
    },
    {
      link: ["/location/kalyannagar"],
      redirect: "/centers/kalyan-nagar",
    },
    {
      link: [
        "/vision-mission-values",
        "/who-we-are",
        "/who-we-are",
        "/why-ovum",
      ],
      redirect: "/about-us",
    },
    {
      link: ["/services/nutrition"],
      redirect: "/our-specialities/dietics",
    },
    {
      link: ["/services/maternal-health-care"],
      redirect: "/our-specialities/fetal-medicine",
    },
    {
      link: ["/services/general-pediatrics"],
      redirect: "/our-specialities/general-medicine-and-diabetology",
    },
    {
      link: ["/services/fertility"],
      redirect: "/our-specialities/ivf",
    },
    {
      link: ["/services/laboratory-and-pharmacy"],
      redirect: "/our-specialities/lab-and-pharmacy",
    },
    {
      link: ["/services/breastfeeding-support"],
      redirect: "/our-specialities/lactation",
    },
    {
      link: ["/services/neonatal-icu"],
      redirect: "/our-specialities/nicu",
    },
    {
      link: ["/services/women-health"],
      redirect: "/our-specialities/obstetrics-and-gynecology",
    },
    {
      link: ["/services/physiotheraphy"],
      redirect: "/our-specialities/physiotherapy",
    },
    {
      link: ["/services/ultrasonography"],
      redirect: "/our-specialities/ultrasonography",
    },
    {
      link: ["/allcenters"],
      redirect: "/centers/kalyan-nagar",
    },
    {
      link: ["/video-testimonials"],
      redirect: "/media/patient-testimonials",
    },
    {
      link: ["//our-doctors/chandrika-anand"],
      redirect: "/doctors/kalyan-nagar/",
    },
    {
      link: ["/our-doctors/ms-athira/?branch=bhattarahalli"],
      redirect: "/doctors/bhattarahalli/",
    },
    {
      link: ["/404"],
      redirect: "/",
    },
    {
      link: ["/our-doctors/ravi-shankar-swamy"],
      redirect: "/doctors/kalyan-nagar/",
    },
    {
      link: ["/our-doctors/nayana-b-s"],
      redirect: "/doctors/kalyan-nagar/",
    },
    {
      link: ["/our-doctors/rashmi-mukund"],
      redirect: "/doctors/kalyan-nagar/",
    },
    {
      link: ["our-doctors/archana-karthik"],
      redirect: "/doctors/kalyan-nagar/",
    },
    {
      link: ["/our-doctors/arpi-sagar-lodha"],
      redirect: "/doctors/kalyan-nagar/",
    },
    {
      link: ["/app-test/"],
      redirect: "/",
    },
    {
      link: ["/our-doctors/ms-athira"],
      redirect: "/doctors/kalyan-nagar/",
    },
    {
      link: ["/our-doctors/chethan-c-s"],
      redirect: "/doctors/kalyan-nagar/",
    },
    {
      link: ["/our-doctors/hima-bindu-k"],
      redirect: "/doctors/kalyan-nagar/",
    },
    {
      link: ["/our-doctors/kesav-murthy/?branch=banashankari"],
      redirect: "/doctors/banashankari/",
    },
    {
      link: ["/our-doctors/ravi-shankar-swamy/"],
      redirect: "doctors/banashankari/",
    },
    {
      link: ["/our-doctors/rashmi-mukund/?branch=banashankari"],
      redirect: "/doctors/banashankari/",
    },
    {
      link: ["/our-doctors/chandrika-anand/?branch=banashankari"],
      redirect: "/doctors/banashankari/",
    },
    {
      link: ["/press-coverage"],
      redirect: "/media/press-release",
    },
    {
      link: ["/our-doctors/ravi-shankar-swamy/?branch=bhattarahalli"],
      redirect: "/doctors/bhattarahalli/",
    },
    {
      link: ["/our-doctors/navya-n"],
      redirect: "/doctors/bhattarahalli/",
    },
    {
      link: ["/our-doctors/vimal-kumar-u"],
      redirect: "/doctors/bhattarahalli/",
    },
    {
      link: ["/our-doctors/prabhu-magudeeswaran"],
      redirect: "/doctors/bhattarahalli/",
    },
    {
      link: ["/our-doctors/hima-bindu-k/?branch=hoskote"],
      redirect: "/doctors/hoskote/",
    },
    {
      link: ["/our-doctors/prabhu-magudeeswaran/?branch=hsrLayout"],
      redirect: "/doctors/hsr-layout/",
    },
    {
      link: ["/services/womens-health"],
      redirect: "/",
    },
    {
      link: ["/doctors"],
      redirect: "/doctors/bhattarahalli/",
    },
    {
      link: ["/press-coverage"],
      redirect: "/media/press-release",
    },
    {
      link: ["/our-doctors/kishore-kotha/"],
      redirect: "/doctors/hoskote/",
    },
    {
      link: ["/our-doctors/prakrutha-s"],
      redirect: "/doctors/hoskote/",
    },
    {
      link: ["/ivf/"],
      redirect: "/",
    },
    {
      link: ["/our-facility/"],
      redirect: "/",
    },
    {
      link: ["/blog/tag/breast-milk/feed/"],
      redirect: "/blog",
    },
    {
      link: ["/fetal-medicine/"],
      redirect: "/blog",
    },
    {
      link: ["/blog/tag/breast-milk/feed/"],
      redirect: "/blog",
    },
    {
      link: ["/our-doctors/diksha-deepak"],
      redirect: "/blog",
    },
    {
      link: ["/our-doctors/sushma-k"],
      redirect: "/blog",
    },
  ];

  function removeSlash(link) {
    if (link.slice(-1) === "/") {
      return link.slice(0, -1);
    } else {
      return link;
    }
  }

  useEffect(() => {
    RedirectionArray.map((item) => {
      let WebLink = removeSlash(window.location.pathname);
      if (item.link.includes(WebLink)) {
        window.location.href = item.redirect;
      }
    });
  }, [location, pathname]);
}
