import styles from "./single.module.css";
//banner title
import BannerTitle from "../../../Assets/UiComponents/BannerTitle/Banner";
import { useParams } from "react-router-dom";
import data from "../../../Assets/Data/A-Z/content.json";
import { useState } from "react";
import { useEffect } from "react";
export default function Single() {
  const [specialist, setSpecialist] = useState(null);
  const { azName, aZletter } = useParams();
  console.log(azName, aZletter);
  useEffect(() => {
    if (aZletter) {
      const specialistData = data.filter(
        (item) => item.title.toLowerCase() == aZletter
      );

      if (specialistData) {
        const filterFinalLetter = specialistData[0]?.contents?.filter(
          (item) => item.link == azName
        );

        setSpecialist(filterFinalLetter[0]);
      }
    }
  }, []);

  return (
    <section className={styles.singleAZWrapper}>
      <section className={styles.singleAZBanner}>
        <img src="https://ik.imagekit.io/d7t01fhx2/Ovum_Hospitals/About_Us/about-us.jpg?updatedAt=1679728470493" />
        <div className={styles.singleAZBannerBackground}></div>
        <article className={styles.singleAZBannerContent}>
          <h1>{specialist?.title}</h1>
        </article>
      </section>
      <section className={styles.singleAZContentWidth}>
        <section className={styles.singleAZContentContainer}>
          <div className={styles.singleAZOverview}>
            <h3>Overview</h3>
            <ul>
              {specialist?.contents?.map((item) => {
                return (
                  <li key={item.id}>
                    <a
                      href={`#${item.title
                        .toLowerCase()
                        .replaceAll(/\s/g, "")}`}
                    >
                      {item.title}
                    </a>
                  </li>
                );
              })}
            </ul>
          </div>
          <div className={styles.singleAZVContents}>
            {specialist?.contents?.map((item) => {
              return (
                <div id={`${item.title.toLowerCase().replaceAll(/\s/g, "")}`}>
                  <h3>{item.title}</h3>
                  <div
                    dangerouslySetInnerHTML={{ __html: item?.content }}
                  ></div>
                </div>
              );
            })}
          </div>
        </section>
      </section>
    </section>
  );
}
