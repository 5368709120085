import React, { useState } from "react";
import { MdOutlineArrowDropDown } from "react-icons/md";
import { Link } from "react-router-dom";
export default function Sidebar(props) {
  const [dropdown, setDropdown] = useState(false);
  return (
    <>
      <article>
        <div>
          <p>
            <Link to={props.link}> {props.title}</Link>
            {props.subMenu && (
              <MdOutlineArrowDropDown onClick={() => setDropdown(!dropdown)} />
            )}
          </p>
        </div>
        {/* 
        {dropdown && props.subMenu  && (
          <>
            <ul>
              {props.subMenu.map((item) => {
                return (
                  <>
                    <li>{item.title}</li>
                  </>
                );
              })}
            </ul>
          </>
        )} */}
        {dropdown && props.subMenu && (
          <>
            <ul className="dropdownShow">
              {props.subMenu.map((item, index) => {
                return (
                  <li key={index}>
                    <Link to={item.link}>{item.title}</Link>
                  </li>
                );
              })}
            </ul>
          </>
        )}
      </article>
    </>
  );
}
