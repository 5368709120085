import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
export default function DoctorProfileRedirection() {
  const { location } = useLocation();
  const { pathname } = window.location;

  const { profileLink } = useParams();
  //   window.location.href = `/our-doctors/${doctorName}`;
  const RedirectionArray = [
    { link: ["archana-karthik"], redirect: "archana-karthik" },
    { link: ["ayesha-gulzar"], redirect: "ayesha-gulzar" },
    { link: ["dr-abhishek-s"], redirect: "abhishek-s-aradhya" },
    { link: ["dr-adarsh-somashekar"], redirect: "adarsh-somashekar" },
    { link: ["dr-akhila-c"], redirect: "akhila-nagaraj" },
    {
      link: ["dr-akshaya-best-gynaecologist-in-bangalore"],
      redirect: "akshaya-doddamani",
    },
    { link: ["dr-chandan-gd"], redirect: "chandan-g-d" },
    { link: ["dr-chandrika-anand"], redirect: "chandrika-anand" },

    { link: ["dr-eash-hoskote"], redirect: "eash-hoskote" },
    { link: ["dr-harini-shetty"], redirect: "harini-p-shetty" },
    { link: ["dr-indrani-c-e"], redirect: "indrani-c-e" },
    { link: ["dr-janice"], redirect: "janice-mathew" },
    { link: ["dr-k-hima-bindu"], redirect: "hima-bindu-k" },
    { link: ["dr-latha-bn-2"], redirect: "latha-b-n" },

    { link: ["dr-maneesha-pandurang-halkar"], redirect: "maneesha-p-h" },

    { link: ["dr-murali-mohan-voona"], redirect: "murali-mohan-voona" },
    { link: ["dr-nagesh-mruthyunjaya"], redirect: "nagesh-mruthyunjaya" },
    { link: ["dr-nayana"], redirect: "nayana-b-s" },
    { link: ["dr-neha-chauhan"], redirect: "neha-chauhan" },

    { link: ["dr-pavana-pakash"], redirect: "pavana-prakash" },
    { link: ["dr-pranavi-n"], redirect: "pranavi-nagendla" },
    { link: ["dr-punith-satish-reddy"], redirect: "punith-s" },

    { link: ["dr-rajesh-babu-m"], redirect: "rajesh-babu-m" },
    { link: ["dr-ramya-shankar"], redirect: "ramya-shankar" },
    { link: ["dr-reeth-sahana"], redirect: "reeth-sahana" },

    { link: ["dr-ruksana-kushter"], redirect: "ruksana-kushter" },
    { link: ["dr-sandhya-shivakumar"], redirect: "sandhya-shivakumar" },
    { link: ["dr-sangeetha-shivaramam"], redirect: "sangeetha-sivaraman" },
    { link: ["dr-sarbari-gupta"], redirect: "sarbari-gupta" },
    { link: ["dr-shanthi-reddy"], redirect: "shanthi-reddy" },
    { link: ["dr-sharat-d-hegde"], redirect: "sharat-d-hegde" },
    { link: ["dr-sharath-galighe"], redirect: "sharath-s-ghalige" },
    { link: ["dr-sharvani-o"], redirect: "sharvani-o" },

    { link: ["dr-shwetha-yadav"], redirect: "shwetha-yadav" },
    { link: ["dr-sreejaya-vk"], redirect: "sreejaya-vk" },
    { link: ["drswetha-vinjamuri"], redirect: "swetha-vinjamuri" },

    { link: ["dr-veda-k-n"], redirect: "k-n-veda" },
    { link: ["dr-vedarth-dash"], redirect: "vedarth-dash" },
    { link: ["dr-venugopal-reddy"], redirect: "venugopal-reddy" },
    { link: ["dr-vidya-rani-k"], redirect: "vidya-rani-k" },
    { link: ["dr-vimal-kumar-u"], redirect: "vimal-kumar-u" },
    { link: ["dr-vippu-dinesh"], redirect: "vuppu-dinesh" },
    { link: ["dr-vishnu-sunil-b-n"], redirect: "vishnu-sunil-bn" },

    { link: ["n-nagendra-prasad"], redirect: "nagendra-prasad" },
    { link: ["prakrutha-s"], redirect: "prakrutha-s" },
  ];

  console.log(RedirectionArray);
  console.log(profileLink);

  useEffect(() => {
    // RedirectionArray.map((item) => {
    //   let WebLink = removeSlash(window.location.pathname);
    //   if (item.link.includes(WebLink)) {
    //     window.location.href = item.redirect;
    //   }
    // });
    RedirectionArray.map((item) => {
      if (item.link.includes(profileLink)) {
        window.location.href = `/our-doctors/${item.redirect}`;
      }
    });
  }, [location, pathname]);
}
