export const SidebarData = [
  {
    id: "1",
    title: "Home",
    link: "/",
  },
  {
    id: "2",
    title: "About Us",
    link: "/about-us",
    // subMenu: [
    //   { id: 2.1, title: "Our Directors", link: "/about-us/our-director" },
    //   { id: 2.2, title: "Our Teams", link: "/about-us/our-team" },
    // ],
  },
  {
    id: "3",
    title: "Our Facilities",
    link: "/our-facilities",
  },
  {
    id: "4",
    title: "Media",
    subMenu: [
      { id: 4.1, title: "Blogs", link: "/blog" },
      { id: 4.2, title: "Gallery", link: "/media/gallery" },
      { id: 4.3, title: "Events", link: "/media/events" },
      {
        id: 4.4,
        title: "Corporate Social Responsibility ",
        link: "/media/corporate-social-responsibility",
      },
      {
        id: 4.5,
        title: "Patient Testimonials",
        link: "/media/patient-testimonials",
      },
      {
        id: 4.7,
        title: "Doctors Talk",
        link: "/media/doctors-talk",
      },
      { id: 4.6, title: "Press Release", link: "/media/press-release" },
      { id: 4.7, title: "A to Z", link: "/a-z" },
    ],
  },
  // {
  //   id: "5",
  //   title: "Packages",
  //   link: "/our-packages",
  // },
  {
    id: "6",
    title: "Career",
    link: "/careers",
  },
  {
    id: "7",
    title: "Contact Us",
    link: "/contact",
  },
  // {
  //   id: "8",
  //   title: "Success Story",
  //   link: "/video-testimonials",
  // },
  {
    id: "9",
    title: "Chairman’s Note",
    link: "/director",
  },
];
